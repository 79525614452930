import { List, ListItem, ListItemText } from '@mui/material';
import styles from '../index.module.scss';
import Layout from '../../../components/Layout';
import ShopNavMenu from '../../../components/NavMenu/ShopNavMenu';
import { Link, Outlet, useLocation } from 'react-router-dom';
import Storage from '../../../utils/Storage';
import Typography from 'antd/es/typography/Typography';

const SalesMain = () => {
  const salesRoutes = [
    { label: 'Sales', link: '/shop/sales/sales' },
    { label: 'Customers', link: '/shop/sales/customers' },
    
  ];

  return (
    
    <Layout  title={`${Storage.getProject()?.name} • SALES`} pages={salesRoutes} navMenu={<ShopNavMenu />}>
      <Outlet />
    </Layout>
  );
};

export default SalesMain;
