import { Box, Button, Chip, Grid, Menu, MenuItem, Pagination, Stack, Typography } from '@mui/material';
import styles from '../index.module.scss';
import { useDispatch, useSelector } from 'react-redux';
import Storage from '../../../utils/Storage';
import NewSale from './NewSale';
import { appActions } from '../../../store/app';
import { exportSalesReport, getSales, removeDeletedSale, searchShopSales } from '../../../store/sales/salesActions';
import { useEffect, useState } from 'react';
import { Sale } from '../../../interfaces/shop/sales';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import ExportSalesForm from './ExportSalesForm';
import FilterListIcon from '@mui/icons-material/FilterList';
import FilterSales from './FilterSales';
import { ChipData } from '../../../interfaces/construction/constants';
import { ShopState } from '../../../store/shop';
import { calculateTotalPaid, calculateTotalPrice, renderMoney } from '../../../utils/functions';
import ConfirmModal from '../../../components/ConfirmModal';
import DeleteIcon from '@mui/icons-material/Delete';
import AutorenewIcon from '@mui/icons-material/Autorenew';
import { deleteAction } from '../../../store/shop/actions/globalActions';
import ListLoadingItem from '../../../components/ListLoadingItem';
import { SalesItem } from '../../../interfaces/shop/salesItem';
import NoData from '../../../components/NoData';
import illustration from '../../../assets/images/illu_sales.png';
import { deleteSellService } from '../../../store/sales/salesServices';
import FormInputs from '../../../components/inputs/FormInputs';
import { Customer } from '../../../interfaces/shop/customers';
import LoadingButton from '@mui/lab/LoadingButton';
import AddIcon from '@mui/icons-material/Add';
import DescriptionIcon from '@mui/icons-material/Description';
import { salesActions, SalesState } from '../../../store/sales';
import { itemActions } from '../../../store/items';

interface SalesItemRowProps {
  index: number;
  name: string;
  unitPrice: string;
  quantity: number;
  totalPrice: string;
}

const SalesItemRow = (props: SalesItemRowProps) => {
  return (
    <div style={{ background: `${props.index % 2 == 0 ? '#f5faff' : 'white'}` }} className="flex py-2 ps-2">
      <div className="basis-1/12">{props.index}</div>
      <div className="basis-4/12 text-center whitespace-nowrap truncate">{props.name}</div>
      <div className="basis-2/12 text-center">{props.unitPrice}</div>
      <div className="basis-2/12 text-center">{props.quantity}</div>
      <div className="basis-3/12 text-center">{props.totalPrice}</div>
    </div>
  );
};

const Sales = (props: any) => {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [count, setCount] = useState(10);
  const appState = useSelector((state: any) => state.app);
  const dispatch = useDispatch();
  const [searchText, setSearchText] = useState('');
  const [exportModal, setExportModal] = useState(false);
  const [isInit, setIsInit] = useState(true);
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>();
  const shopState: ShopState = useSelector((state: any) => state.shop);
  const salesState: SalesState = useSelector((state: any) => state.sales);
  const project = Storage.getProject()._id;
  const [query, setQuery] = useState('');
  const [sale, setSale] = useState<Sale | null>();
  const [showFilter, setShowFilter] = useState(false);
  const token = Storage.getToken();
  const [fetching, setFetching] = useState(false);
  const [chipData, setChipData] = useState<any>([]);
  const [formState, setFormState] = useState({
    payment: {} as any,
    customer: {} as Customer | any,
    customerText: '' as string | any,
    dateFrom: null as any,
    dateTo: null as any,
  });

  const filterHandler = (filterData: any) => {
    setChipData(filterData);
  };

  const handleDelete = (chipToDelete: ChipData) => () => {
    setChipData((chips: any[]) => chips.filter((chip: ChipData) => chip.key !== chipToDelete.key));
    if (chipToDelete.label === 'Customer') {
      setFormState({ ...formState, customer: {}, customerText: '' });
    }
  };

  const handlePageChange = (event: any, value: number) => {
    setPage(value - 1);
    dispatch(salesActions.setFetchedSales(false));
  };

  const onCustomerChanged = (customer: any | Customer, isObject: boolean) => {
    if (isObject) {
      setFormState({ ...formState, customer, customerText: '' });
      let newChip = {
        key: chipData[chipData.length - 1]?.key,
        filter: customer._id,
        id: 'customer',
        label: 'Customer',
        value: customer?.name,
      };
      setChipData([...chipData, newChip]);
    } else {
      setFormState({ ...formState, customerText: customer, customer: null });
    }
  };

  const filterSaleElements = [
    {
      // label: 'Customer',
      identifier: 'customer',
      inputComponent: 'select',
      values: shopState.customers,
      onChange: (customer: Customer | string, isObject: true) => onCustomerChanged(customer, isObject),
      value: formState?.customer?.name || formState.customerText,
      name: 'name',
      id: '_id',
    },
  ];

  const deleteSaleHandler = () => {
    if (sale?._id) {
      dispatch(
        deleteAction(deleteSellService, sale._id, token, 'Sales', () => {
          dispatch(removeDeletedSale(salesState.sales, sale._id));
          setSale(null);
        })
      );
    }
    dispatch(itemActions.setFetchedItems(false));
  };

  useEffect(() => {
    if (chipData.length > 0) {
      let queryString = '';
      chipData.forEach((data: any) => {
        queryString += `&${data.id}=${data.filter}`;
      });
      setQuery(queryString);
      dispatch(salesActions.setFetchedSales(false));
    } else if (chipData.length === 0 && query !== '') {
      setQuery('');
      dispatch(salesActions.setFetchedSales(false));
    }
  }, [chipData]);

  useEffect(() => {
    if (!salesState.fetchedSales) {
      dispatch(
        getSales(`?project=${project}&page=${page}&sort=-date&limit=${rowsPerPage}&action=sales${query}`, token)
      );
    }
  }, [page, rowsPerPage, token, salesState.fetchedSales, dispatch, project, query]);

  useEffect(() => {}, [salesState.salesCount]);

  useEffect(() => {
    let timer = setTimeout(() => {
      if (searchText) {
        dispatch(searchShopSales(`?name=${searchText}&project=${project}&action=sales${query}`, token));
        setIsInit(false);
      } else {
        if (!appState.isFetching && salesState.fetchedSales && !isInit) {
          dispatch(salesActions.setFetchedSales(false));
        }
      }
    }, 1000);

    return () => {
      clearTimeout(timer);
    };
  }, [dispatch, project, searchText, token]);

  const exportSales = () => {
    if (query.includes('date[gte]') && query.includes('date[lte]')) {
      dispatch(
        exportSalesReport(
          `?project=${project}&limit=20000&sort=-date&action=sales${query}`,
          token,
          `${Storage.getProject().name} • SALES REPORT`
        )
      );
    }
  };

  let pages = Math.ceil(salesState.salesCount / count);

  return (
    <>
      {/* <TopActions
        setNew={() => dispatch(appActions.setOpenForm({ open: true, action: 'add' }))}
        export={() => setExportModal(true)}
        entity="Sale"
        setSearchText={setSearchText}
        addNew={true}
        filter={true}
        setFilter={setShowFilter}
      /> */}
      <Box className="my-6 ms-auto w-full text-right">
        <LoadingButton
          variant="contained"
          startIcon={<DescriptionIcon />}
          loading={appState.isExportingExcel}
          className={styles.normalButton}
          style={{ fontFamily: 'Poppins' }}
          onClick={() => exportSales()}
        >
          Export Excel
        </LoadingButton>
        <Button
          variant="contained"
          startIcon={<AddIcon />}
          style={{ fontFamily: 'Poppins' }}
          className={styles.newButton}
          onClick={() => dispatch(appActions.setOpenForm({ open: true, action: 'add' }))}
        >
          Add New Sale {props?.entity}
        </Button>
      </Box>
      {/* <h1>{`${isOnline} checking`}</h1> */}
      {chipData.length > 0 && (
        <Stack direction="row" spacing={1} style={{ marginTop: '1rem', marginBottom: '1.5rem' }}>
          {chipData.map((data: any) => {
            const value = data?.value ? data.value : data.filter;
            return (
              <Chip
                key={data.key}
                label={`${data.label}: ${value}`}
                onDelete={data.label === 'React' ? undefined : handleDelete(data)}
              />
            );
          })}
        </Stack>
      )}
      {/* <SalesTable
        count={count}
        page={page}
        setPage={setPage}
        rowsPerPage={rowsPerPage}
        setRowsPerPage={setRowsPerPage}
      /> */}
      <Grid container spacing={2}>
        <Grid item xs={4}>
          <div className="bg-white pb-2">
            <div className="flex items-center">
              <div className="ml-5 w-full">
                {!fetching && (
                  <Box className="flex">
                    {filterSaleElements.map((element: any, index: number) => {
                      return <FormInputs key={index} element={element} />;
                    })}
                    <div className="mr-4 cursor-pointer">
                      <FilterListIcon className="mt-4" onClick={() => setShowFilter(true)} />
                    </div>
                  </Box>
                )}
              </div>
            </div>
            <hr />
            {!appState.isFetching && (
              <>
                <div style={{ height: '72vh', overflowY: 'scroll' }}>
                  {salesState?.sales.length > 0 &&
                    salesState?.sales.map((currentSale: Sale) => (
                      <div
                        key={currentSale._id}
                        style={{
                          backgroundColor: currentSale._id === sale?._id ? '#EBF6FF' : 'white',
                        }}
                        className="p-2 m-4 rounded-md cursor-pointer shadow-md"
                        onClick={() => setSale(currentSale)}
                      >
                        <div className="flex justify-between">
                          <div>{currentSale?.customer?.name ?? 'Customer'}</div>
                          <Box className={currentSale?.isCredit ? styles.payment_credit : styles.payment_paid}>
                            <Typography style={{ fontSize: '0.8rem !important' }}>
                              {currentSale?.isCredit ? 'Credit' : 'Paid'}
                            </Typography>
                          </Box>
                        </div>
                        <div>{currentSale.list.length} Items</div>
                        <div className="flex justify-between">
                          <div className="font-bold">{calculateTotalPrice(currentSale.list)} RWF</div>
                          <div>{currentSale.date.substring(0, 10).replaceAll('-', '.')}</div>
                        </div>
                      </div>
                    ))}
                  <hr className="mb-2" />
                  <div className="ms-auto w-[80%]">
                    <Pagination count={pages} page={page + 1} onChange={handlePageChange} size="small" />
                  </div>
                  {salesState?.sales?.length === 0 && <NoData />}
                </div>
              </>
            )}
            {appState.isFetching && (
              <div style={{ height: '29rem' }}>
                <ListLoadingItem />
              </div>
            )}
          </div>
        </Grid>
        <Grid item xs={8}>
          <div className="h-[81vh] bg-white overflow-y-scroll">
            {sale?.list && (
              <div className="p-6">
                <div className="flex items-center justify-between mb-4">
                  <div className="bg-gray-100 rounded-lg p-2 cursor-pointer" onClick={() => setSale(null)}>
                    <ArrowBackIosNewIcon />
                  </div>
                  <div className="bg-gray-100 rounded-lg p-2 cursor-pointer">
                    <MoreVertIcon
                      onClick={(event: any) => {
                        setAnchorEl(event.target);
                      }}
                    />
                  </div>
                </div>
                <div className="flex justify-between">
                  <div>
                    <div className="font-semibold text-xl mb-2">{Storage.getProject()?.name}</div>
                    <div className="text-sm flex">
                      <div className="font-semibold me-2">Address:</div>
                      <div>{Storage.getProject()?.address ?? 'Address'}</div>
                    </div>
                    <div className="text-sm flex">
                      <div className="font-semibold me-2">Contact :</div>
                      <div>{Storage.getProject()?.contact ?? 'Phone'}</div>
                    </div>
                    <div className="text-sm flex">
                      <div className="font-semibold me-2">Tin Number :</div>
                      <div>{Storage.getProject()?.tinNumber ?? Storage.getUser()?.company?.tinNumber}</div>
                    </div>
                  </div>
                  <div>
                    <div className="font-semibold text-xl mb-2">Sales</div>
                    <div className="text-sm flex">
                      <div className="font-semibold me-2">Time:</div>
                      <div>{new Date(sale.date).toLocaleTimeString()}</div>
                    </div>
                    <div className="text-sm flex">
                      <div className="font-semibold me-2">Date:</div>
                      <div>{new Date(sale.date).toLocaleDateString()}</div>
                    </div>
                    <div className="text-sm flex">
                      <div className="font-semibold me-2">P.O Number:</div>
                      <div>{sale.salesInvoice}</div>
                    </div>
                    <div className="text-sm flex">
                      <div className="font-semibold me-2">Customer Name:</div>
                      <div>{sale?.customer?.name ?? 'Supplier'}</div>
                    </div>
                  </div>
                </div>
                <div className="flex mt-8 bg-slate-100 py-2 ps-2">
                  <div className="basis-1/12">SL</div>
                  <div className="basis-4/12 text-center">Item Name</div>
                  <div className="basis-2/12 text-center">Unit Price</div>
                  <div className="basis-2/12 text-center">Quantity</div>
                  <div className="basis-3/12 text-center">Total Price</div>
                </div>
                {sale?.list?.map((salesItem: SalesItem, index: number) => (
                  <SalesItemRow
                    index={index + 1}
                    name={salesItem.item.name}
                    unitPrice={renderMoney(salesItem.unitPrice)}
                    quantity={salesItem.quantity}
                    totalPrice={renderMoney(salesItem.quantity * salesItem.unitPrice)}
                  />
                ))}

                <div className="flex justify-between">
                  <div className="">
                    <div className="font-semibold mt-10 mb-4">Payment Info:</div>
                    <div className="font-semibold">Amount Received: </div>
                    <div className="font-semibold">{calculateTotalPaid(sale?.list)}</div>
                  </div>
                  <div className="mt-10 w-4/12">
                    <div className="flex justify-between">
                      <div className="font-semibold">SubTotal:</div>
                      <div>{calculateTotalPrice(sale.list)} RWF</div>
                    </div>
                    <div className="flex justify-between">
                      <div className="font-semibold">Tax</div>
                      <div>0</div>
                    </div>
                    <div className="flex font-bold text-xl justify-between text-primaryColor mt-4">
                      <div>Total:</div>
                      <div>{calculateTotalPrice(sale.list)}</div>
                    </div>
                  </div>
                </div>
                <div className="my-8 font-semibold">Approved by:</div>
                <div className="flex justify-between border-t-2 border-gray-200 pt-2">
                  <div>Authorized Signature</div>
                  <div className="me-12">Date</div>
                </div>
              </div>
            )}
            {!sale?.list && (
              <div className="h-[81vh] bg-white w-full flex items-center justify-center">
                <img className="w-[16vw] h-[35vh]" src={illustration} alt="Purchase" />
              </div>
            )}
          </div>
          {Boolean(anchorEl) && (
            <Menu open={Boolean(anchorEl)} onClose={() => setAnchorEl(null)} anchorEl={anchorEl}>
              <MenuItem
                onClick={() => {
                  dispatch(appActions.setOpenForm({ open: true, action: 'update' }));
                  setAnchorEl(null);
                }}
              >
                <AutorenewIcon />
                Update
              </MenuItem>
              <MenuItem
                onClick={() => {
                  dispatch(appActions.setIsDeleting(true));
                  setAnchorEl(null);
                }}
              >
                <DeleteIcon />
                Delete
              </MenuItem>
            </Menu>
          )}
          {appState.isDeleting && (
            <ConfirmModal
              onConfirm={() => deleteSaleHandler()}
              content={`Are you sure you want to delete Sales of ${sale?.list?.length} items, on ${sale?.date.substring(
                0,
                10
              )} by ${sale?.customer?.name}`}
            />
          )}
        </Grid>
      </Grid>
      {exportModal && <ExportSalesForm open={exportModal} setOpen={setExportModal} />}
      {showFilter && <FilterSales filterHandler={filterHandler} open={showFilter} setOpen={setShowFilter} />}
      {appState.openForm.open && (
        <NewSale
          sale={sale}
          close={() => {
            dispatch(appActions.setOpenForm({ open: false, action: '' }));
          }}
        />
      )}
    </>
  );
};

export default Sales;
