import { shopActions } from '..';
import { exportExcel, requestFailed, setResponseAction } from '../../../utils/functions';
import { appActions } from '../../app';
import { getShopCustomersService, searchCustomersService } from './customersServices';

export const getShopCustomers = (query: string, token: string) => {
  return async (dispatch: any) => {
    dispatch(appActions.setIsFetching(true));
    try {
      const res = await getShopCustomersService(query, token);
      if (res.response?.data.status === 'fail' || res.response?.data.status === 'error') {
        dispatch(setResponseAction(res));
      } else {
        dispatch(shopActions.setCustomers(res.data));
        dispatch(appActions.setCount({ count: res.count }));
      }

      dispatch(appActions.setIsFetching(false));
      dispatch(shopActions.setFetchedCustomers(true));
    } catch (err) {
      console.log(err);
      dispatch(setResponseAction(err));
    }
  };
};

// EXPORT EXCEL
export const exportCustomersAction = (query: string, token: string, excelName: string) => {
  return async (dispatch: any) => {
    try {
      dispatch(appActions.setIsExportingExcel(true));
      const res = await getShopCustomersService(query, token);
      if (requestFailed(res)) {
        dispatch(setResponseAction(res, 'fail', res.message));
      } else {
        const debtors = res?.data?.filter((customer: any) => customer.debt > 0);
        const clients = debtors?.map((customer: any) => {
          return {
            id: customer?._id,
            CustomerName: customer?.name,
            debt: customer?.debt,
            Telephone: customer?.contact ?? 'None',
            tinNumber: customer?.tinNumber ?? 'None',
          };
        });
        console.log("Clientsssssss ",  clients);
        exportExcel(clients, excelName);
      }
      dispatch(appActions.setIsExportingExcel(false));
    } catch (err) {
      console.log(err);
    }
  };
};

export const searchCustomers = (projectId: string, query: string, token: string) => {
  return async (dispatch: any) => {
    dispatch(appActions.setIsFetching(true));
    try {
      const res = await searchCustomersService(projectId, query, token);
      if (res.response?.data.status === 'fail' || res.response?.data.status === 'error') {
        dispatch(setResponseAction(res));
      } else {
        dispatch(shopActions.setCustomers(res.data));
        dispatch(appActions.setCount({ count: res.count }));
      }

      dispatch(appActions.setIsFetching(false));
      dispatch(shopActions.setFetchedCustomers(true));
    } catch (err) {
      console.log(err);
      dispatch(setResponseAction(err));
    }
  };
};

export const customerPayWholeDebt = (projectId: string, query: string, token: string) => {
  return async (dispatch: any) => {
    dispatch(appActions.setIsFetching(true));
    try {
      const res = await searchCustomersService(projectId, query, token);
      if (res.response?.data.status === 'fail' || res.response?.data.status === 'error') {
        dispatch(setResponseAction(res));
      } else {
        dispatch(shopActions.setCustomers(res.data));
        dispatch(appActions.setCount({ count: res.count }));
      }

      dispatch(appActions.setIsFetching(false));
      dispatch(shopActions.setFetchedCustomers(true));
    } catch (err) {
      console.log(err);
      dispatch(setResponseAction(err));
    }
  };
};
